import { ISmartrrOrderLineItem } from "@smartrr/shared/entities/OrderLineItem";
import { IPurchasable } from "@smartrr/shared/entities/Purchasable";
import { IPurchaseState } from "@smartrr/shared/entities/PurchaseState";
import { IPurchaseStateLineItem } from "@smartrr/shared/entities/PurchaseState/CustomerPurchaseLineItem";
import { ISmartrrBundleConfig } from "@smartrr/shared/entities/SellingPlanGroup";
import { viewShopifyId } from "@smartrr/shared/utils/ensureShopifyGid";

export enum BundleLineItemTypes {
  BUNDLE_PRODUCT = "BUNDLE_PRODUCT",
  BUNDLE_LINE = "BUNDLE_LINE",
}

export interface ICustomAttributesItems {
  /** Variant Id */
  id: string | number;
  quantity: number;
  selling_plan?: string;
}

export interface ISmartrrInfoCustomAttributes {
  bundles: { name: string; slug: string };
  date: number;
  items: ICustomAttributesItems[];
  page: string;
}

export interface IShopifyCollection {
  id: string;
  products: { edges: IShopifyCollectionProductNode[] };
  title: string;
  handle?: string;
  __typename?: string;
}
export interface IShopifyCollectionProductNode {
  node: { id: string; title: string; __typename?: string };
  __typename?: string;
}

export function getBundleByBundleId(
  allBundles: ISmartrrBundleConfig[],
  purchaseState: IPurchaseState
): ISmartrrBundleConfig | undefined {
  if (!purchaseState.containsBundle || !allBundles) {
    return;
  }
  // If containsBundle, then there should always be a parent product
  const bundleParent = getBundleParentFromLineItems(purchaseState.stLineItems);
  return allBundles.find(bundle => bundle.id === bundleParent?.bundleId!);
}

export function getBundlePackByShopifyVariantId(
  bundle: ISmartrrBundleConfig,
  bundleParentItem: IPurchaseStateLineItem
) {
  // Check to ensure proper item is passed in
  if (!isCPSLineItemBundleParent(bundleParentItem)) {
    return;
  }
  const { shopifyId } = bundleParentItem.vnt;
  return bundle.packs.find(pack => pack.buyWithVariant === viewShopifyId(shopifyId));
}

export function filterBundleParentsFromPurchasables(
  allBundles: ISmartrrBundleConfig[],
  purchasable: IPurchasable
): boolean {
  return allBundles.some(bundle =>
    bundle.packs.some(pack =>
      purchasable?.vnts?.some(vnt => pack.buyWithVariant === viewShopifyId(vnt.shopifyId))
    )
  );
}

export const isCPSLineItemBundleParent = (li: IPurchaseStateLineItem): boolean => {
  if (!li?.bundleType) {
    return false;
  }
  return li.bundleType === BundleLineItemTypes.BUNDLE_PRODUCT;
};

export const isCPSLineItemBundleLine = (li: IPurchaseStateLineItem): boolean => {
  if (!li?.bundleType) {
    return false;
  }
  return li.bundleType === BundleLineItemTypes.BUNDLE_LINE;
};

export const isCPSLineItemApartOfBundle = (li: IPurchaseStateLineItem): boolean => {
  if (!li?.bundleType) {
    return false;
  }
  return isCPSLineItemBundleParent(li) || isCPSLineItemBundleLine(li);
};

export const isOrderLineItemBundleItem = (order: ISmartrrOrderLineItem) =>
  order.discountAllocations.some(line => line.description === "Bundle Item");

export const getBundleParentFromLineItems = (
  lines: IPurchaseStateLineItem[] | null
): IPurchaseStateLineItem | undefined => {
  if (!lines?.length) {
    return undefined;
  }
  return lines.find(li => li.bundleType === BundleLineItemTypes.BUNDLE_PRODUCT);
};
