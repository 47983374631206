import { CurrencyCode } from "@smartrr/shared/currencyCode";

export enum DiscountValueType {
  FIXED,
  PERCENTAGE,
}

export enum DiscountApplicationType {
  MANUAL,
  SCRIPT,
  DISCOUNT_CODE,
  AUTOMATIC,
}

export enum DiscountTargetType {
  LINE_ITEM,
  SHIPPING_LINE,
}

export enum DiscountTargetSelection {
  ALL,
  ENTITLED,
  EXPLICIT,
}

export enum DiscountAllocationMethod {
  ACROSS,
  EACH,
  ONE,
}

export interface IDiscount {
  allocationMethod?: DiscountAllocationMethod;
  code?: string;
  currency?: CurrencyCode; // if fixed
  description?: string;
  rejectionReason?: string;
  recurringCycleLimit?: number;
  targetSelection?: DiscountTargetSelection;
  targetType?: DiscountTargetType;
  title?: string;
  type: DiscountApplicationType;
  value: number;
  valueType: DiscountValueType;
  orderWideDiscount?: boolean;
  quantityConsidered?: boolean;
  vendorId?: string;
}

export type ILineItemDiscount = IDiscount;

export interface IOrderLineItemDiscount extends IDiscount {
  calculatedValue: number;
}

export interface IPurchaseStateLineItemDiscount extends IDiscount {
  usageCount: number;
}

export type IOrderDiscount = IDiscount;
export interface IPurchaseStateDiscount extends IDiscount {
  usageCount: number;
}
